<template>
    <div class="home">
        <div class="min-width">
            <div class="header">
                <img src="../assets/img/home/logo.png" alt="" />
            </div>
        </div>
        <div class="line"></div>
        <div class="min-width">
            <div class="content"></div>
        </div>
        <div class="line"></div>
        <div class="min-width">
            <div class="footer">
                <p>
                    <a href="/PrivacyPolicy" target="_blank" style="color: white">Privacy Policy</a> |
                    <a href="/TermsOfService" target="_blank" style="color: white">Terms of service</a> | Contact us by email: info@tpoker.net
                </p>
                <br />
                <p>Copyright©2019-2021 Tpoker All Rights Reserved.</p>
            </div>
        </div>
    </div>
</template>

<style lang="less">
* {
    margin: 0px;
    padding: 0px;
}
</style>

<style lang="less" scoped>

.min-width {
    min-width: 1400px;
    background-color: rgb(15, 1, 46);
}
.line {
    width: 100%;
    height: 1px;
    background-color: rgb(50, 31, 88);
}
.header {
    height: 100px;
    width: 1400px;
    margin: 0 auto;
}
.content {
    width: 100%;
    height: 910px;
    background-image: url("../assets/img/home/main.jpg");
    background-position: center center;
}
.footer {
    width: 100%;
    height: 60px;
    line-height: 20px;
    padding: 20px 0px;
    text-align: center;
}
.footer p {
    color: white;
}
</style>
