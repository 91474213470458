import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: Home 
    },
    {
        path: "/ThunderPoker",
        name: "ThunderPoker",
        component: () =>
            import("../views/ThunderPoker.vue"),
    },
    {
        path: "/PrivacyPolicy",
        name: "PrivacyPolicy",
        component: () =>
            import("../views/PrivacyPolicy.vue"),
    },
    {
        path: "/TermsOfService",
        name: "TermsOfService",
        component: () =>
            import("../views/TermsOfService.vue"),
    },
    {
        path: "/:catchAll(.*)",
        name: "Error",
        component: () => import("@/views/Error.vue"),
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
