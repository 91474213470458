<template>
  <!-- <nav>
    <router-link to="/">home</router-link> |
    <router-link to="/thunderPoker">thunderPoker</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="less">
html,body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
// @import "@/assets/css/base.less";
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
